<template>
    <div>
        <sn-table-group ref="snTableGroup" :tableGroupAttributes="tableGroupAttributes" :commonMethods.sync="commonMethods"  @handleChange="handleChange"></sn-table-group>
    </div>
</template>
<script>

import { commonData } from '../sn-table-group-common';
import { selfData } from './sn-table-group-user';
import commonMethodMixin from '../commonMethodMixin';
import { mapActions } from 'vuex';
export default {
    name: 'user',
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            selfData,
            infoPage: 'userinfo',
            // downUrl: DOWN_URL + '/api/FileDownLoad/OnPostExport?funId=0002',
            // 筛选项
            keyword: '', // 关键字
            departmentValue: '',
            roleValue: '',
            exportItemId: 'user'
        };
    },
    watch: {
        departmentValue() {
            this.getrole();
        }
    },
    mounted() {
        this.getorg();
        this.getrole();
    },
    methods: {
        ...mapActions([
            'getUserList',
            'getDepNameList',
            'getRoleNameList',
            'deleteUser'
        ]),
        async getTableData() {
            let res = await this.getUserList({
                keywords: this.keyword,
                depID: this.departmentValue,
                roleid: this.roleValue,
                pageindex: this.tableGroupAttributes.snPage.currentPage,
                pagesize: this.tableGroupAttributes.snPage.tiaoshu
            });
            this.tableGroupAttributes.snTable.table.data = res.Data;
            this.tableGroupAttributes.snPage.count = Number(res.Total);
            this.tableGroupAttributes.loading = false;
        },
        // 获得机构列表
        async getorg() {
            let res = await this.getDepNameList();
            this.tableGroupAttributes.snSearch.treeselectData[0].list = res;
        },
        // 获得角色列表
        async getrole() {
            let res = await this.getRoleNameList({
                depid: this.departmentValue
            });
            let list = res || [];
            this.tableGroupAttributes.snSearch.selectData[0].list = list;
            let isInclude = list.some(item => item.ID === this.roleValue);
            if (!isInclude && this.roleValue) {
                this.roleValue = "";
                this.$refs.snTableGroup.$refs.snSearch.resetSearchValue('roleValue', '');
                this.handleChange('search', ['', 'roleValue']);
            }
        },
        // 删除数据
        async delData(id, done) {
            let res = await this.deleteUser({
                ids: id
            });
            this.delOver(res, done);
        },
    },
};
</script>
<style lang="scss" scoped>
</style>