const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "用户名",
                prop: "NAME"
            }, {
                label: "姓名",
                prop: "REALNAME"
            }, {
                label: "性别",
                prop: "SEX",
                transList: {
                    1: '男',
                    2: '女'
                }
            }, {
                label: "部门",
                prop: "DEPARTMENTMC"
            }, {
                label: "角色",
                prop: "ROLENAME"
            }, {
                label: "APP权限",
                prop: "QXMC"
            }, {
                label: "联系电话",
                prop: "MOBILE"
            }, {
                label: "电子邮箱",
                prop: "EMAIL"
            }, {
                label: "备注",
                prop: "REMARK"
            }],
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        treeselectData: [{
            isShow: true,
            label: '部门',
            clearable: true,
            placeholder: "请选择部门",
            list: [],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'departmentValue',
            operateType: 'search',
            searchable: true,
            disableBranchNodes: false,
            width: "160px",
        }],
        selectData: [{
            label: '角色',
            clearable: true,
            placeholder: "请选择角色",
            list: [],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'roleValue',
            id: 'roleValue',
            operateType: 'search',
            isShow: true
        }],
    },
    snButton: {
        buttonData: [{
            isShow: true,
            btnType: 'button',
            operateType: 'buttonNew',
            name: '新建',
            round: true,
            backColor: '#28ccd9',
        }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export { selfData };